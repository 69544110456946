<template>

  <div class="market-wrapper">

    <div class="market-name">
      <span class="bold">{{ market_name }}</span>
    </div>

    <!--
    <div v-if="parseInt(outcome.market_id) === 18">

      <div class="row outcome-row" v-for="(o,index) in totals_18" :key="getKey(index)" >

< ! --        v-bind:disabled="isLocked(o.under)"-- >
< ! --        @click="addPick(fixture.sport_id,fixture.match_id,outcome.market_name,market_id,fixture.competitor_1,fixture.competitor_2,o.under)"-- >

        <div
            v-bind:id="o.under.odd_id"
            class="col totals-item"
             :class="getClasses(o.under)"
            style="min-height: 2.8em;">

              <div class="outcome-odd">

                <Odd
                    v-bind:sport_id="fixture.sport_id"
                    market_name="Totals"
                    v-bind:home_team="fixture.competitor_1"
                    v-bind:away_team="fixture.competitor_2"
                    v-bind:odd_id="o.under.odd_id"
                    v-bind:show_direction="show_direction"
                    v-bind:match_id="fixture.match_id"
                    market_id="18"
                    v-bind:outcome_id="o.under.outcome_id"
                    v-bind:specifier="o.under.specifier"
                    v-bind:active="o.under.active"
                    v-bind:status="o.under.status"
                    v-bind:odds="o.under.odd"
                    v-bind:probability="o.under.probability"
                    v-bind:producer_id="o.under.producer_id"
                    v-bind:producer_status="o.under.producer_status"
                    v-bind:previous_odds="o.under.previous_odds"
                    v-bind:alias="o.under.alias"
                    v-bind:pick="o.under"/>

              </div>

        </div>

< !--        v-bind:disabled="isLocked(o.over)"-- >
< !--        @click="addPick(fixture.sport_id,fixture.match_id,outcome.market_name,market_id,fixture.competitor_1,fixture.competitor_2,o.over)"-- >

        <div
            class="col totals-item"
            v-bind:id="o.over.odd_id"
            :class="getClasses(o.over)"
            style="min-height: 2.8em;">

              <div class="outcome-odd">

                <Odd
                    v-bind:sport_id="fixture.sport_id"
                    market_name="Totals"
                    v-bind:home_team="fixture.competitor_1"
                    v-bind:away_team="fixture.competitor_2"
                    v-bind:odd_id="o.over.odd_id"
                    v-bind:show_direction="show_direction"
                    v-bind:match_id="fixture.match_id"
                    v-bind:market_id="market_id"
                    v-bind:outcome_id="o.over.outcome_id"
                    v-bind:specifier="o.over.specifier"
                    v-bind:active="o.over.active"
                    v-bind:status="o.over.status"
                    v-bind:odds="o.over.odd"
                    v-bind:probability="o.over.probability"
                    v-bind:producer_id="o.over.producer_id"
                    v-bind:producer_status="o.over.producer_status"
                    v-bind:previous_odds="o.over.previous_odds"
                    v-bind:alias="o.over.alias"
                    v-bind:pick="o.over"/>

              </div>

        </div>

      </div>

    </div>

    <div v-else-if="parseInt(outcome.market_id) === 238">

      <div class="row outcome-row" v-for="(o,index) in totals_238" :key="getKey(index)" >

        <div
            v-bind:id="o.under.odd_id"
            class="col totals-item"
            :class="getClasses(o.under)"
            v-bind:disabled="isLocked(o.under)"
            style="min-height: 2.8em;">

          <div class="outcome-odd">

            <Odd
                v-bind:sport_id="fixture.sport_id"
                market_name="Totals"
                v-bind:home_team="fixture.competitor_1"
                v-bind:away_team="fixture.competitor_2"
                v-bind:odd_id="o.under.odd_id"
                v-bind:show_direction="show_direction"
                v-bind:match_id="fixture.match_id"
                market_id="18"
                v-bind:outcome_id="o.under.outcome_id"
                v-bind:specifier="o.under.specifier"
                v-bind:active="o.under.active"
                v-bind:status="o.under.status"
                v-bind:odds="o.under.odd"
                v-bind:probability="o.under.probability"
                v-bind:producer_id="o.under.producer_id"
                v-bind:producer_status="o.under.producer_status"
                v-bind:previous_odds="o.under.previous_odds"
                v-bind:alias="o.under.alias"
                v-bind:pick="o.under"/>

          </div>

        </div>

        <div
            class="col totals-item"
            v-bind:id="o.over.odd_id"
            :class="getClasses(o.over)"
            v-bind:disabled="isLocked(o.over)"
            style="min-height: 2.8em;">

          <div class="outcome-odd">

            <Odd
                v-bind:sport_id="fixture.sport_id"
                market_name="Totals"
                v-bind:home_team="fixture.competitor_1"
                v-bind:away_team="fixture.competitor_2"
                v-bind:odd_id="o.over.odd_id"
                v-bind:show_direction="show_direction"
                v-bind:match_id="fixture.match_id"
                v-bind:market_id="market_id"
                v-bind:outcome_id="o.over.outcome_id"
                v-bind:specifier="o.over.specifier"
                v-bind:active="o.over.active"
                v-bind:status="o.over.status"
                v-bind:odds="o.over.odd"
                v-bind:probability="o.over.probability"
                v-bind:producer_id="o.over.producer_id"
                v-bind:producer_status="o.over.producer_status"
                v-bind:previous_odds="o.over.previous_odds"
                v-bind:alias="o.over.alias"
                v-bind:pick="o.over"/>

          </div>

        </div>

      </div>

    </div>


    <div v-else-if="parseInt(outcome.market_id) === 166">

      <div class="row outcome-row" v-for="(o,index) in totals_166" :key="getKey(index)" >

        <div
            v-bind:id="o.under.odd_id"
            class="col totals-item"
            :class="getClasses(o.under)"
            v-bind:disabled="isLocked(o.under)"
            style="min-height: 2.8em;">

          <div class="outcome-odd">

            <Odd
                v-bind:sport_id="fixture.sport_id"
                market_name="Totals"
                v-bind:home_team="fixture.competitor_1"
                v-bind:away_team="fixture.competitor_2"
                v-bind:odd_id="o.under.odd_id"
                v-bind:show_direction="show_direction"
                v-bind:match_id="fixture.match_id"
                market_id="166"
                v-bind:outcome_id="o.under.outcome_id"
                v-bind:specifier="o.under.specifier"
                v-bind:active="o.under.active"
                v-bind:status="o.under.status"
                v-bind:odds="o.under.odd"
                v-bind:probability="o.under.probability"
                v-bind:producer_id="o.under.producer_id"
                v-bind:producer_status="o.under.producer_status"
                v-bind:previous_odds="o.under.previous_odds"
                v-bind:alias="o.under.alias"
                v-bind:pick="o.under"/>

          </div>

        </div>

        <div
            class="col totals-item"
            v-bind:id="o.over.odd_id"
            :class="getClasses(o.over)"
            v-bind:disabled="isLocked(o.over)"
            style="min-height: 2.8em;">

          <div class="outcome-odd">

            <Odd
                v-bind:sport_id="fixture.sport_id"
                market_name="Total Corners"
                v-bind:home_team="fixture.competitor_1"
                v-bind:away_team="fixture.competitor_2"
                v-bind:odd_id="o.over.odd_id"
                v-bind:probability="o.over.probability"
                v-bind:show_direction="show_direction"
                v-bind:match_id="fixture.match_id"
                v-bind:market_id="market_id"
                v-bind:outcome_id="o.over.outcome_id"
                v-bind:specifier="o.over.specifier"
                v-bind:active="o.over.active"
                v-bind:status="o.over.status"
                v-bind:odds="o.over.odd"
                v-bind:producer_id="o.over.producer_id"
                v-bind:producer_status="o.over.producer_status"
                v-bind:previous_odds="o.over.previous_odds"
                v-bind:alias="o.over.alias"
                v-bind:pick="o.over"/>

          </div>

        </div>

      </div>

    </div>
    -->

   <!--    v-else -->
    <div  class="row outcome-row">
      <div
          v-for="(o,index) in outcome"
          :key="index"
          v-bind:id="o.odd_id"
          class="col text-center"
          style="min-height: 2.8em;"
          v-bind:disabled="isLocked(o)"
          :class="getClasses(o)">

        <div class="outcome-odd">

          <Odd
              v-bind:sport_id="fixture.sport_id"
              v-bind:market_name="market_name"
              v-bind:home_team="fixture.home_team"
              v-bind:away_team="fixture.away_team"
              v-bind:pick="o"
              v-bind:show_direction="show_direction"
              v-bind:match_id="fixture.match_id"
              v-bind:market_id="market_id"
              v-bind:outcome_id="o.outcome_id"
              v-bind:probability="o.probability"
              v-bind:specifier="specifier"
              v-bind:active="o.active"
              v-bind:status="status"
              v-bind:odds="o.odds"
              v-bind:alias="o.alias"
              v-bind:producer_id="producer_id"
              v-bind:producer_status="producer_status"
              v-bind:previous_odds="o.previous_odds"/>
        </div>

      </div>
    </div>

  </div>

</template>

<script>

import Odd from './Odd'

export default {
  components: {
    Odd
  },
  name: "Outcome",
  props: {
    outcome: {
      type: Array,
      required: true
    },
    market_id: {
      required: true
    },
    market_name: {
      required: true
    },
    specifier: {
      required: true
    },
    status_name: {
      required: true
    },
    status: {
      required: true
    },
    producer_status: {
      //type: Array,
      required: true
    },
    producer_id: {
      //type: Array,
      required: true
    },
    fixture: {
      type: Object,
      required: true
    },
  },
  methods: {
    isLocked: function(o){

      if(parseInt(this.producer_id) === 1 ) {

        return  parseInt(this.status) > 0 || parseInt(o.active) === 0 || parseInt(this.producer_status) === 0;

      } else {

        return  parseInt(this.status) > 0 || parseInt(o.active) === 0;

      }
    },
    getKey: function(index){

      return Math.random().toString(10).replace('0.', 'specifiers-id-' +index+ '-');

    },
    /**
     *
     * @param sport_id
     * @param match_id
     * @param market_name
     * @param market_id
     * @param competitor_1
     * @param competitor_2
     * @param picks
     */
    pick: function (sport_id, match_id, market_name, market_id, competitor_1, competitor_2, picks) {

      var vm = this;

      console.log('addPick match_id=>'+match_id+' market_name =>'+market_name+' market_id=>'+market_id+' picks=>'+JSON.stringify(picks));

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("bslip");

      if (!Array.isArray(bSlip)) {

        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.sport_id = sport_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;

      if (!bSlip || bSlip === '' || bSlip === "") {

        bSlip = [];
      }

      if (bSlip.length > 0) {

        vm.jQuery.each(bSlip, function (k, v) {

          if (v !== undefined && v !== null) {

            if (v.odd_id === odd_id) {

              isunselect = true;
            }

            if (v.match_id === match_id) {

              delete bSlip[k];
            }
          }
        });
      }

      if (!isunselect) {

        bSlip.push(picks);
      }

      var bt = [];

      vm.jQuery.each(bSlip, function (k, v) {

        if (v !== undefined && v) {

          bt.push(v);
        }

      });

      this.saveObject("bslip", bt);
      this.autoRefreshUI(this.$vnode.tag);

    },
    getOddDirectionClass: function (outcome) {

      if(parseInt(outcome.active) === 0 || parseInt(outcome.status) !== 0) {

        return '';
      }

      if(parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds) ) {

        return 'arrow green up';

      }

      return 'arrow red down';

    },

    getClasses: function (o) {

      //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'locked btn-odd' : 'btn-odd';

      return "btn-odd small-txt mr-1 mt-1 " +
          " match-"+this.fixture.match_id +
          " producer-" + this.producer_id +
          " market-" + this.market_id +
          " outcome-" + o.outcome_id +
          " specifier-" + this.specifier;

    },

  },
  mounted: function () {

    /*
    var vm = this;

    this.EventBus.$on('odds:'+vm.id,function(payload){

      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;

      if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

        vm.previousOdds = vm.odd;
        vm.odd = payload.odd;
      }

      vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
      vm.getStatusClass();

      vm.directionClass = vm.getOddDirectionClass();

    });

    this.EventBus.$on('producer:status',function(payload){

      var producer_id = payload.producer_id;
      var producer_status = payload.producer_status;

      if(parseInt(producer_id) === 1 ) {

        vm.producerStatus = producer_status;
        vm.directionClass = vm.getOddDirectionClass();
        vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
        vm.getStatusClass();
      }

    });
    */

  },
  data: function (){
    return {
      show_direction: false
    }
  }
}
</script>